.custom-empty {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transform: translate(20px, 100px);
}
.custom-empty .ant-empty-description {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 1px;
}

.empty-component-wrapper {
}
