.first-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow-y: hidden;
}
.category-card-heading {
  text-transform: capitalize;
}
