@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@100;300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;500;600;700;800&display=swap");
:root {
  --yellow: #ebff00;
  --white-font: #ffffff;
  --grey: #e5e5e5;
  --dark-blue: #172b4d;
  --student-blue: #172b4d;
  --brown: #684715;
  --dark-yellow: #cbaf1e;
  --orange: #db6d07;
  --normal-blue: #073abe;
  --pitch-black: #000000;
  --lemon-green: #96ad38;
  --pitch-green: #5f9e20;
  --grey-bg: hsl(0, 0%, 94%);
  --ash: #a9a29c;
  --neon-blue: #205364;
  --dark-green: #2a9728;
  --warm-red: #eb264a;
  --dark-red: #4e272769;
  --warm-blue: #172b4d;
  --sky-blue: #90c5f5;
  --warm-pink: #f8baba;
  --dark-purple: #710a6d;
  --blood-red: #aa0b28;
  --shadow: 7px 5px 6px 0 #ddd;
  --radius10: 10px;
  --radius5: 5px;
  --flex: flex;
  --blue-new: #172b4d;
  --hover-blue: #6a758d;
  --table-th: #f1f4f6;
  --border: #dbdbdb;
  --padding10: 10px;
  --padding5: 5px;
}

main {
  flex: 0.82 1;
  width: calc(100% - 220px);
  float: right;
  position: relative;
  padding: 65px 5px 40px 0px;
}
body {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  min-height: 100%;
  background: #f1f4f6 !important;
  font-family: "Sen", sans-serif !important;
  font-size: 13px;
}

.ant-table-content {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.ant-table-content::-webkit-scrollbar {
  width: 7px !important;
  height: 7px;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

input,
button,
select,
option,
textarea {
  font-family: inherit;
  outline-width: 0;
  border: 0;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-spinner {
  height: 200px;
  width: 200px;
  margin-right: 10px;
}
.loadingName {
  font-size: 20px;
  display: flex;
  align-items: center;
  color: green;
}

.loadingDots {
  animation: blink 1s infinite;
}

@media (max-width: 850px) {
  main {
    padding: 16px 16px 16px 16px;
  }
}
@media (max-width: 650px) {
  main {
    flex: 0;
    width: auto;
    float: initial;
    position: absolute;
    padding: 10px 0;
  }
  .ant-select {
    margin-right: 0;
  }
}

//new modal style
.addstudent-popup {
  .cancel-button {
    background-color: var(--dark-blue);
    text-align: center;
    width: 100px;
  }
  .ant-modal-header {
    padding: 10px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
  }
  .ant-modal-close-x {
    display: block;
    width: 54px;
    height: 22px;
    font-size: 16px;
    font-style: normal;
    line-height: 43px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
  }
  .vertical-scroll-modal {
    .modal-content {
      max-height: 600px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .ant-form-item {
    width: auto !important;
  }
  .ant-picker {
    background-color: var(--white-font);
    width: 100% !important;
    height: 42px;
    border-radius: 5px;
  }
  .ant-modal-body {
    padding: 10px;
    margin: 0px 0 0 0;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-select {
    margin: 0;
  }

  .ant-input-affix-wrapper {
    padding: 0;
    border: none;
  }
  #inputs {
    background-color: #fff;
    height: 40px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    font-size: 13px;
  }

  .ant-picker-input {
    background-color: var(--white);
    border-radius: 5px;
    text-align: end;
    height: 40px;
    padding: 8px;
    border-radius: 5px;
  }

  .ant-select-single .ant-select-selector {
    background-color: #fff;
    height: 40px;
    width: 100%;
    border-radius: 5px;
  }
  .ant-select-single .ant-select-selector:hover {
    border: 1px solid var(--grey-bg);
    outline: none;
  }
  .antd-row .ant-form-item-row {
    background-color: var(--grey-bg);
  }
  .ant-input {
    background-color: var(--white-font);
    border-radius: 5px;
    height: 42px;
  }
}

//new stle
.ant-pagination {
  display: flex;

  button {
    background: #fff;
    padding: 5px;
  }

  .ant-pagination-item {
    padding: 0;
  }
}

.ant-table::-webkit-scrollbar-thumb {
  background-color: blue !important;
}

.ant-table::-webkit-scrollbar {
  height: 8px !important;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-radius: 5px 0 0 5px;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  border-radius: 0 5px 5px 0;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  cursor: text;
  width: 100%;
  height: auto;
  padding: 5px 11px;
  border-radius: 5px;
  margin: 0 0 0 0;
}

.ant-picker-input > input {
  padding: 5px;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  &:hover {
    background: none;
  }
}

.ant-select-selector {
  min-width: 75px;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 60px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  text-align: left;
}

.ant-picker-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 0px 0;
  margin-top: 5px;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #172b4d;
  border-color: #172b4d;
  background: #fff;
}

.ant-radio-button-wrapper {
  border: 1px dashed var(--border) !important;
  // border-radius: var(--radius5);
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: var(--blood-new);
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background: none !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #fdfeff;
  background: #172b4d;
  border-color: #172b4d;
}

.ant-radio-button-wrapper-checked:not(
    [class*=" ant-radio-button-wrapper-disabled"]
  ).ant-radio-button-wrapper:first-child {
  border-right-color: #172b4d;
}

.ant-radio-button-wrapper-checked {
  background: var(--dark-blue);
  color: white;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  color: #ffffff;
  border-color: #172b4d;
}

.ant-radio-button-wrapper:hover {
  position: relative;
  color: #121111;
}

.ant-table-thead > tr > th {
  border: 0.1px solid var(--hover-blue) !important;
}

.ant-table table {
  // overflow-x: hidden;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 16px;

  & > * > tr > th,
  & > * > tr > td {
    // border-right-color: #fff;

    &:last-child {
      border-right: none;
    }
  }
}

/*******Global CSS Start*****/
.ant-popover-content {
  position: relative;
  left: 90%;
  border-radius: 0;
}

.ant-input-group .ant-input {
  min-width: 300px;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  left: -1px;
  padding: 0;
  border: 0;
  background: var(--dark-blue);
}

.markData-container
  .header
  .ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  border: none;
}

.loadinganimation {
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  width: 130px;
  top: 40%;

  img {
    border-radius: 100px;
  }
  h3 {
    font-size: 14px;
  }
}
.ant-table-thead > tr > th {
  text-align: left !important;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 20px !important;
  text-align: center !important;
  .ant-btn-primary {
    background: var(--blue-new);
    border: none;
  }
}
.ant-modal-confirm-body {
  text-align: center !important;
}
.ant-modal-confirm-body > .anticon {
  float: none !important;
  margin-right: 16px;
  font-size: 31px !important;
}

.ant-select-lg {
  font-size: 14px !important;
}

h1 {
  font-size: 18px;
  margin: 0;
}

.ant-form-item-label > label {
  height: 40px;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 0;
  justify-content: center;
}
.buttonbottom {
  padding: 10px 0;
}

.ant-btn {
  // min-height: 40px;
}

.ant-table-thead > tr > th {
  border: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px dashed var(--border) !important;
  border-radius: var(--radius5);
}

.ant-input {
  border: 1px dashed var(--border) !important;
  border-radius: var(--radius5);
}

.ant-btn {
  border-radius: var(--radius5) !important;
}

.ant-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
  gap: 5px 0;
}

.ant-modal-body {
  padding: 15px 15px !important;
}

.ant-modal-content {
  border-radius: 10px !important;

  .ant-modal-header {
    border-radius: 10px 10px 0 0;
  }
}
.ant-modal-header {
  background-color: var(--table-th) !important;
}

.modelinput {
  .ant-row {
    margin: 0 !important;
    gap: 5px;
  }
}
.ant-table-column-title,
.ant-table-thead > tr > th {
  text-align: left;
}
.ant-table-tbody .ant-table-cell {
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 4.5px 8px;
  box-shadow: none;
  border-bottom: 1px dashed var(--border);
  cursor: pointer;
  text-align: left !important;
  div {
    padding: 0 !important;
  }

  span {
    text-align: left !important;
    font-weight: 400 !important;

    a {
      font-weight: 400 !important;
    }
  }
  .anticon {
    color: var(--dark-blue);
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    padding: 0;

    svg {
      font-size: 16px;
    }
  }
}

.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
  border: 1px dashed var(--border);
}

.ant-select-arrow {
  top: 55% !important;
}

.ant-select-single .ant-select-selector {
  height: 40px !important;
  line-height: auto !important;

  .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 40px !important;
    transition: all 0.3s, visibility 0s;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
    line-height: 40px;
  }
}
.float-label {
  .as-placeholder {
    top: 10px;
  }
}

.ant-table-thead > tr > th {
  background: var(--dark-blue) !important;
  color: var(--white-font) !important;
  padding: 10px !important;
}

.ant-radio-button-wrapper {
  line-height: 40px !important;
  height: 40px !important;
}

.ant-picker {
  border: 1px dashed var(--border) !important;
}

.ant-modal-body {
  .ant-row {
    display: flex;
    flex-flow: row wrap;
    margin: 0 0px !important;
    padding-bottom: 10px;

    .ant-col {
      padding: 0 !important;
    }

    .float-label {
      position: relative;
      width: 95%;
    }
  }
}

.cancel-button {
  min-width: 100px;
}

.ant-form-item {
  margin-bottom: 0 !important;
}

.ant-table {
  padding: 10px !important;
  border-radius: 10px !important;
  background: var(--white-font) !important;
}

.flex-new {
  display: flex;
}

/*******Global CSS END*****/
