.dashboard-container {
  position: relative;

  .dashboard-data-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    // position: absolute;
    // top: 10px;
    // padding-left: 30px;
    padding-top: 5px;
    // background-color: var(--white-font);
    .card-1 {
      background: linear-gradient(
        217deg,
        rgba(3, 157, 29, 0.2),
        rgba(10, 98, 212, 0.2)
      );
    }
    .card-2 {
      background: linear-gradient(
        217deg,
        rgba(231, 194, 10, 0.2),
        rgba(193, 9, 235, 0.2)
      );
    }
    .card-3 {
      background: linear-gradient(
        217deg,
        rgba(14, 185, 232, 0.2),
        rgba(255, 0, 140, 0.2)
      );
    }
    .card-4 {
      background: linear-gradient(
        217deg,
        rgba(249, 18, 18, 0.2),
        rgba(100, 186, 3, 0.2)
      );
    }
    .card-5 {
      background: linear-gradient(
        217deg,
        rgba(47, 3, 182, 0.2),
        rgba(179, 255, 0, 0.2)
      );
    }
    .card-6 {
      background: linear-gradient(
        217deg,
        rgba(78, 224, 219, 0.2),
        rgba(119, 0, 255, 0.2)
      );
    }
    .card-7 {
      background: linear-gradient(
        217deg,
        rgba(170, 240, 39, 0.2),
        rgba(53, 1, 175, 0.2)
      );
    }
    .card-8 {
      background: linear-gradient(
        217deg,
        rgba(24, 113, 246, 0.2),
        rgba(255, 0, 0, 0.2)
      );
    }

    .card-container {
      // position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 240px;
      height: 100px;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      background-color: var(--white-font);
      cursor: pointer;

      &:hover {
        box-shadow: none;
      }

      p {
        margin-bottom: 0px;
      }

      .card-name {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 30px;
        color: var(--dark-blue);
        padding: 10px;
        border-radius: 5px;
      }
      .dashboard-icon {
        color: var(--dark-blue);
        height: 35px;
        width: 35px;
        margin-right: 25px;
        float: left;
      }
      .number-icon-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // padding: 20px;
        padding-bottom: 19px;
        padding-left: 40px;
      }
      .card-total {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 30px;
        color: #172b4d;
      }

      .dashboard-icons {
        font-size: 40px;
        font-weight: 600 !important;
      }
      .suggesion-icon {
        color: var(--dark-blue);
        height: 50px;
        width: 50px;
        float: left;
        margin-right: 25px;
        padding-bottom: 10px;
      }
      .suggesion-bulb-icon {
        color: var(--dark-blue);
        height: 60px;
        width: 60;
        float: left;
        margin-right: 25px;
        padding-bottom: 15px;
      }
    }
  }
}
/*famous book*/
.latest-book-lists {
  position: relative;
  top: 0;
  h2 {
    font-size: 24px;
    font-weight: 500;
    padding-top: 10px;
  }
  .famous-card-div {
    text-align: center;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .famous-book-cards {
    flex-basis: calc(25% - 85px);
    margin-right: 10px;
    margin-bottom: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    max-width: 500px !important;
    span {
      display: block;
      font-size: 14px;
      text-align: center;
      font-weight: 600;
    }
  }
  .icon-container {
    align-items: center;
  }
  .star-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: rgb(187, 204, 0);
    clip-path: polygon(
      50% 0%,
      63% 38%,
      100% 38%,
      69% 59%,
      82% 100%,
      50% 75%,
      18% 100%,
      31% 59%,
      0% 38%,
      37% 38%
    );
  }
}
.ant-carousel .slick-dots-bottom {
  top: 230px;
}
.ant-carousel .slick-dots.slick-dots-bottom li button {
  background-color: var(--dark-blue) !important;
}
