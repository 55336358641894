.app-container {
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .header-container {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px;
    padding: 3px 5px 0px 25px;
    justify-content: space-between;
    display: flex;
    background: var(--white-font);
    position: fixed;
    left: 0;
    right: 0;
    z-index: 9999;
    height: 50px;
    margin-left: 185px;
    .topbar-logo {
      display: flex;
      gap: 20px;
      padding: 0px;
      .top-heading-vidhya {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #000000;
        letter-spacing: 1px;
        margin-top: 5px;
      }
      img {
        width: 40px;
        height: 40px;
        display: block;
      }
    }
  }

  .bell-icon {
    background-color: var(--grey-bg);
    margin: 0 5px;
    padding: 2px;
    border-radius: 50px 60px;
  }
  .ant-input-search,
  .ant-input,
  .ant-input-group-addon,
  .ant-btn {
    background-color: var(--grey) !important;
    color: var(--black);
    border-radius: 20px;
  }

  .search-notifications-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .icons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 54px;
  }

  .librarian-heading-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .librarian {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #000000;
    padding: 5px 10px;
  }
  .profile-img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 20px;
  }

  .profile-container {
    background-color: #c4c4c4;
    width: 46px;
    height: 46px;
    border-radius: 25px;
    // margin-left: 20px;
    margin-right: 0px;
    margin-right: 53px;
    cursor: pointer;
  }
  /*left menu styles*/
  .leftmenu-block {
    // margin-top: 48px;
    flex: 0;
    background-color: var(--dark-blue);
    overflow-x: hidden;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: auto;
    height: 100vh;
    border-radius: 0;
    z-index: 9999;
    box-shadow: 0px 30px 16px 0px #000;
    .edpedia-logo {
      background-color: #172b4d;
      width: 100%;
      height: 60px;
    }
    svg {
      font-size: 18px;
    }
    ::-webkit-scrollbar {
      display: none;
    }
    a {
      color: var(--white-font);
    }

    .topbar-components-wrapper {
      .topbar {
        padding-left: 5%;
      }
    }

    .ant-menu-sub.ant-menu-inline {
      padding-left: 12px;
    }
    .ant-menu-submenu-arrow {
      color: var(--white-font);
    }
    .edpedia-logo-container {
      position: fixed;
      top: 0;
      background-color: #172b4d !important;
      padding: 18px;
      text-align: center;
      z-index: 999;
    }
    .ant-menu-item {
      &:hover {
        background: var(--yellow);
      }
    }

    .ant-menu-inline.ant-menu-root .ant-menu-item,
    .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
      &:hover {
        background: var(--hover-blue);
        color: var(--white-font);

        .anticon svg {
          color: var(--white-font);
        }

        a {
          color: var(--white-font);
        }

        .ant-menu-submenu-arrow {
          color: #000;
        }
      }
    }

    .ant-menu-inline.ant-menu-root .ant-menu-item,
    .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
      color: var(--white-font);
      padding: 10px !important;
    }

    .ant-menu-submenu-selected {
      .ant-menu-submenu-title:first-child {
        background: var(--hover-blue);

        .anticon svg {
          color: #000;
        }

        .ant-menu-title-content {
          color: var(--white-font);
        }

        .ant-menu-submenu-arrow {
          color: var(--white-font);
        }
      }
    }

    h1 {
      color: #fff;
      padding: 1px 0 0 5px;
      color: var(--yellow);
      font-size: 23px;
      margin: 0;
    }

    .leftmenu {
      background-color: var(--dark-blue);
      color: var(--yellow);
      margin-bottom: 40px;
      margin-top: 95px;
    }

    .leftmenu:hover {
      color: var(--yellow);
    }

    .ant-menu-title-content a {
      color: var(--white-font);
    }

    .anticon svg {
      color: var(--white-font);

      margin: 0 10px 0 0;
    }

    .ant-menu-item {
      padding: 10px 20px;
    }

    .ant-menu-submenu-title {
      padding: 10px 0;
      cursor: pointer;
    }

    .ant-menu .ant-menu-item:hover {
      background-color: white;
      color: var(--dark-blue);
    }

    .ant-menu-submenu .ant-menu {
      background-color: var(--dark-blue);
    }

    .ant-menu-inline .ant-menu-item::after {
      border-right: 7px solid var(--yellow);
    }
    .ant-menu-inline {
      border-right: none;
    }
    .ant-menu-item-selected {
      background: var(--hover-blue) !important;

      .anticon svg {
        color: var(--white-font);
      }

      .ant-menu-title-content {
        a {
          color: var(--white-font);
        }
      }

      .ant-menu-title-content:hover {
        a {
          color: var(--dark-blue);
        }
      }
    }

    .ant-menu-item-selected:hover {
      color: var(--dark-blue);
    }
    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      color: #fff;
      border-color: #172b4d;
      background: #172b4d;
      border: 1px solid var(--yellow);
    }

    .ant-menu-submenu-title {
      .ant-menu-sub {
        .ant-menu-item-selected {
          .anticon svg {
            color: var(--dark-blue);
          }
        }
      }
    }
    .topnavlogoBig {
      width: 199px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 10px;
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      background-color: #172b4d;
    }
    .topnavlogoSmall {
      width: 79px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 10px;
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      background-color: #172b4d;
      padding-left: 24px;
    }
    .navbtns {
      background: none;
      padding: 6px 0 0 10px;
      border: 1px solid var(--yellow);
      border-radius: 5px;
    }
  }
}

/*collapse*/

@media (max-width: 650px) {
  .left-menu-container {
    border-radius: 0;
    position: relative;
    width: 100%;
  }
}
