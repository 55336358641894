.categories-cards-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 10px;
  .category-card {
    height: 200px;
    width: 200px;
    // background-image: url(https://plus.unsplash.com/premium_photo-1664006989021-4628d0604c36?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fHJlYWR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=600&q=60);
    background-image: url(https://plus.unsplash.com/premium_photo-1667251760504-096946b820af?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJvb2t8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=600&q=60);
    background-size: cover;
    background-position: center;
    cursor: pointer;
    transition: 0.5s;
    position: relative;
    text-align: center;
    border-radius: 7px 7px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
  .book-content-div {
    background-color: var(--dark-blue);
    color: var(--white-font);
    opacity: 0.8;
    position: absolute;
    bottom: 0;
  }
  .category-card-heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 45px;
    color: #ffffff;
    text-align: center;
    display: block;
    position: absolute;
    bottom: 0;
    text-align: center;
  }
  .author-actions {
    padding: 2px 0;
    width: 200px;
    border-radius: 0 0 7px 7px;
    h3 {
      display: block;
    }
    .icons {
      display: none;
    }
  }
  .author-actions:hover .icon {
    display: block;
  }
  .author-actions:hover h3 {
    display: none;
  }
}
