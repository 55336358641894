.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;

  .self-number-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #172b4d;
    color: #ffffff;
    height: 15px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    width: 190px;
    top: 155px;
  }
}

.author-actions {
  // background-color: darkcyan;
  background-color: #80bd9e;
  color: var(--white-font);
  padding: 5px;
  text-align: center;
  transition: 0.5s;
  border-radius: 5px;
  position: absolute;
  bottom: 0px;
  width: 90%;
}

.author-actions h3 {
  color: var(--white-font);
  font-weight: 600;
  display: flex;
  justify-content: center !important;
  align-items: center;
  transform: translate(0%, 20%);
  font-size: 17px;
}
.books-name {
  color: darkcyan;
}
.author-actions:hover h3 {
  display: none;
}

.author-actions .icon {
  display: none;
}

.author-actions:hover .icon {
  display: block;
}

.author-actions .icon button {
  margin-right: 5px;
  padding: 5px !important;
}

@media (max-width: 768px) {
  .authors-card-container {
    flex-basis: calc(50% - 30px); // On smaller screens, show 2 cards per row
  }
}

.intro {
  // padding-top: 15px;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 10px;
  padding-bottom: 5px;
  .selfNum {
    margin-bottom: 50px;
  }
  span {
    display: block;
    font-size: 13px;
    font-weight: 500;
    padding-left: 5px;
    text-transform: capitalize;
    margin-bottom: 5px;
  }
}
