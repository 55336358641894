.first-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.book-total-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.books {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 52px;
  color: #000000;
  margin-bottom: 0px;
}

.second-item-container {
  margin-top: 0px;
  position: relative;
  .rentBook-form {
    position: absolute;
    top: -40px;
    right: 0px;
    .rent-btn {
      background-color: var(--dark-blue);
      color: var(--white-font);
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

.tab {
  display: flex;
  flex-direction: row;
  color: #000000;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}

.tab-number-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  background-color: #172b4d;
  color: #ffffff;
  margin-left: 8px;
}

.bg-green {
  background-color: #55b035;
}

.bg-red {
  background-color: var(--dark-red);
}
.bg-brown {
  background-color: var(--brown);
}
.bg-neun {
  background-color: var(--neon-blue);
}
.bg-orange {
  background-color: var(--orange);
}
.bg-green {
  background-color: rgb(44, 217, 44);
}
.drop-downs-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 70px;
  left: 63%;
}

.genre {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #000000;
  margin-left: 16px;
}
.category-select {
  text-transform: capitalize;
}
/*add hover to card*/
.username-btn {
  background-color: transparent;
  border: 2px solid var(--white-font);
  text-align: center;
}
.username-btn:hover {
  background-color: transparent;
  border: 2px solid var(--white-font);
  text-align: center;
}
.username-btn:focus {
  background-color: transparent;
  border: 2px solid var(--white-font);
  text-align: center;
}
.username-btn:active {
  background-color: transparent;
  border: 2px solid var(--white-font);
  text-align: center;
}

@media (max-width: 768px) {
  .cards-container {
    flex-basis: calc(50% - 35px);
  }
}

.card-content-container {
  display: flex;
  flex-direction: column;
}

.card-main-heading-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  padding: 0px 18px 0px 6px;
}

.ant-card-body {
  padding: 0px;
}

.rupi {
  padding-left: 6px;
}

.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 85%;
}
.dropdown-container .ant-btn {
  padding: 10px 15px 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #868585;
  border-radius: 50px;
  border: none;
}

.dropdown-container .anticon svg {
  height: 10px;
  width: 16px;
  color: #868585;
  margin-left: 3px;
}

.ant-table-thead .ant-table-cell {
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  background-color: var(--dark-blue) !important;
  padding: 5px;

  &:hover {
    background-color: #172b4d;
  }

  &:first-child {
    border-radius: 8px 0 0 8px;
  }

  &:last-child {
    border-radius: 0 8px 8px 0px;
  }
}
.ant-table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  background: #f0f0f0;
  // background-color: var(--grey);
}
.ant-table table {
  border-spacing: 0 3px;
  margin: -6px 10px 0px 0px;
}
.ant-table-tbody .ant-table-cell {
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  padding: 3px 10px !important;
  background-color: var(--white-font) !important;
  padding: 2px !important;

  &:first-child {
    border-radius: 8px 0 0 8px;
  }

  &:last-child {
    border-radius: 0 8px 8px 0px;
  }
}
