.add-books-button {
    text-align: center;
    padding: 5px 10px;
    height: 35px;
    background-color: var(--dark-blue) !important;
    margin-right: 102px;
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    /* line-height: 30px; */
    border-radius: 5px !important;
    color: var(--white-font);
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.centered_spin {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}

.button-icon {
    color: var(--yellow);
    height: 24px;
    width: 24px;

}

.spin {
    margin-left: 450px !important;
    margin-top: 150px !important;
}

.add-books-button:hover {
    background-color: #172b4d;
    color: #ffffff;
}

.add-books-button:focus {
    background-color: #172b4d;
    color: #ffffff;
}

.icon_img {
    height: 15px;
    width: 15px;
}

.sidebar-imges {
    height: 17px;
    width: 17px;
    color: var(--yellow) !important;

}

.ant-table-thead>tr>th {
    border: 1px solid white;
}



.ant-table-column-sorter {
    margin-left: 4px;
    color: var(--white-font);
    font-size: 0px !important;
    transition: color 0.3s;

}

.ant-table-tbody .ant-table-cell .anticon {
    background: var(--dark-blue);
    color: #fff;
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    padding: 0;
}


.ant-space .ant-space-item {
    padding: 0 !important;
}

.ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    text-align: start;
}

.spin-fevorite {
    margin-left: 300px;
    margin-top: 30px;
}

.cancel-button {
    background-color: var(--blood-red) !important;
    text-align: center;
    width: 100px;
    color: var(--white-font) !important;
    border-radius: 5px;
}

.submit-button {
    background-color: var(--dark-blue) !important;
    text-align: center;
    width: 100px;
    color: var(--white-font) !important;
    border-radius: 5px;
}

.blue-button {
    background-color: var(--dark-blue) !important;
    text-align: center;
    width: auto;
    color: var(--white-font) !important;
    border-radius: 5px;
}

.ant-input {
    background-color: var(--white-font);
    border-radius: 5px;
    height: 42px;
}

.anticon svg {
    height: 20px;
    width: 20px;
    padding: 2px;
}


.books-count-container {
    background-color: var(--dark-blue);
    width: 43.75px;
    height: 43.75px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 20px;
}

.books-count {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: #ffffff;
    padding-top: 15px;
}

/*input-search styles*/
.ant-input-group .ant-input {
    padding: 10px;
    padding-left: 20px;
    /* border-top-left-radius: 20px;
    border-bottom-left-radius: 20px; */
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 15px;
    color: #8e8e8e;
    outline: none;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child {
    border: none;
    width: 50px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: var(--dark-blue);
}

.anticon-search {
    width: 30px;
    height: 30px;
    padding-top: 5px;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    border: none;
    background-color: var(--dark-blue);
}

.anticon-search {
    background-color: var(--student-blue);
    color: #fff;
}

.modal-text {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-transform: capitalize;
    padding-bottom: 15px;
}

.ant-table-thead .ant-table-column-sorter-up,
.ant-table-column-sorter-down {
    color: var(--white-font);

    font-size: 21px;

}