.author-card-content-container {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  // padding: 10px;
  a {
    text-transform: capitalize;
    text-decoration: underline;

    height: 20px;
    font-size: 17px;
    font-weight: 500;
  }
}
.author-card-content-container .author-genre {
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  padding-top: 10px;
}
.author-card-content-container .author-genres {
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 40px;
}

.author_card_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;
}

.authors-card-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 #0003;
  height: auto;
  padding: 15px 3px;
  text-align: center;
  text-transform: capitalize;
  width: 18.5%;
  position: relative;
  top: 0;

  img.card-image {
    height: 150px;
    object-fit: contain;
    width: 100%;
  }
  .author-actions {
    // background-color: darkcyan;
    background-color: #80bd9e;
    color: var(--white-font);
    padding: 5px;
    text-align: center;
    transition: 0.5s;
    border-radius: 5px;
    position: absolute;
    bottom: 10px;
    width: 95%;
  }

  .author-actions h3 {
    color: var(--white-font);
    font-weight: 600;
    display: flex;
    justify-content: center !important;
    align-items: center;
    transform: translate(0%, 20%);
  }

  .author-actions:hover h3 {
    display: none;
  }

  .author-actions .icon {
    display: none;
    padding: 0 !important;
  }

  .author-actions:hover .icon {
    display: block;
    padding: 0 !important;
  }

  .author-actions .icon button {
    margin-right: 5px;
    padding: 5px !important;
  }
  .authore-genre {
    color: darkcyan;
  }
}

@media (max-width: 768px) {
  .authors-card-container {
    flex-basis: calc(50% - 30px); // On smaller screens, show 2 cards per row
  }
}

.intro {
  // padding-top: 15px;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 10px;
  padding-bottom: 5px;
  span {
    display: block;
    font-size: 13px;
    font-weight: 500;
    padding-left: 5px;
    text-transform: capitalize;
  }
}
