.authors-cards-main-container {
}
.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: var(--dark-blue);
  font-size: 30px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  display: flex;
}
