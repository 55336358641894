.profile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  .profile-header {
    padding: 0px 0px 10px 0;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
    span {
      font-size: 20px;
      font-weight: 600;
    }
  }
  .profile-card {
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    height: auto;
    width: 100%;
    margin-bottom: 15px;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
  }
}
